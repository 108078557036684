
const Table = (data: any) => {
  const {item} = data
  let headers = item.render_config?.table?.headers.map((item: any) => item?.label);
  let tableBody = item.data?.map((item: any) => item);

  const formatMonth = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'short', year: 'numeric' });
  };

  return (
    <div>
      <h2>{item.render_config?.table?.label}</h2>
      <table border={1} cellPadding="10">
        <thead>
          <tr>
            {headers.map((item: any, index: any) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableBody.map((row: any, index: any) => {
            return <tr key={index}>
              {Object.keys(row).map((keyy: any, i: any) => {
                return <td key={i}>{row[keyy]}</td>
              })}
            </tr>
          })}
        </tbody>
      </table>
    </div>
  );
}

export { Table };