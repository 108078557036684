/** @jsxImportSource @emotion/react */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, { useState } from "react";
import { icon } from "../../assets/Images";
import { css } from '@emotion/react';
import AccordianContentForm from "./AccordianContentForm";
import Modal from '@mui/material/Modal';
import CustomButton from "../Button/Button";
import { colors, typography } from '../../scss/colors';

interface AccordionProps {
  title: string;
  content: object;
  isActive: boolean;
  onClick: () => void;
  onDelete?: () => void;
  children?: React.ReactNode;
  onUpdate: (updatedContent: any) => void;
  setErrors: any;
  errors: any;
}

const accordianSection = css`
  margin-bottom: 15px;
`;
const buttonSection = css`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
const regBackButton = css`
background-color: ${colors.white};
color: ${colors.buttonOrange};
border-color: ${colors.buttonOrange};
width: 100px;
height: 40px;
border-radius: 4px;
${typography("700", "16px")};
&:hover {
  background-color: ${colors.white};
  color: ${colors.buttonOrange};
  border-color: ${colors.buttonOrange};
}
`;

const regNextButton = css`
background-color: ${colors.buttonOrange};
color: ${colors.white};
width: 100px;
height: 40px;
border-radius: 4px;
${typography("700", "16px")};
padding: 0px;
&:hover {
  background-color: ${colors.buttonOrange}; 
  color: ${colors.white};
}
`;
const modalMain = css`
  padding: 0px !important;
  .logout-model {
    padding: 0px 0px 40px 0px;
    text-align: center;
    .logout-model-header {
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: ${colors.crimson};
      margin-bottom: 45px;
    }
  }
`;

const modalBoxStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: ${colors.white};
  box-shadow: 24px;
  border-radius: 5px;
  padding: 30px;
  max-height: 90vh;
  overflow-y: auto;
  text-align: center;
`;

const Accordian = ({
  title,
  content,
  isActive,
  onClick,
  onDelete,
  children,
  onUpdate,
  setErrors,
  errors,
}: AccordionProps) => {

  const iconButtonStyle = {
    backgroundColor: "#7E7E7E",
    color: "#FFFFFF",
    width: "24px",
    height: "24px",
    "&:hover": {
      backgroundColor: "#7E7E7E",
    },
  };

  const iconStyle = {
    width: "16px",
    height: "16px",
  };

  const expandIcon = isActive ? <Remove sx={iconStyle} /> : <Add sx={iconStyle} />;
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
  };
  

  return (
    <div css={accordianSection}>
      <Accordion
        sx={{ background: "#F5F5F5", borderRadius: "4px", boxShadow: "none" }}
        expanded={isActive}
        onChange={onClick}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel-content"
          id="panel-header"
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", height: {
      xs: "50px", 
      sm: "20px", 
    }, }}>
            <Typography
              sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "20px",
                textAlign: "left",
              }}
            >
              {title}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <IconButton sx={iconButtonStyle} onClick={(e) => { e.stopPropagation(); onClick(); }}>
                {expandIcon}
              </IconButton>
              {onDelete && (
                 <IconButton sx={iconButtonStyle} onClick={(e) => { e.stopPropagation();setShowModal(true) }}>
                 <DeleteOutlineIcon sx={iconStyle}/>
               </IconButton>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ background: "#FFFFFF",
          padding: {
            xs: "0px", 
            sm:"10px"
          }, }}>
          <Typography>
            <AccordianContentForm
              content={content}
              onUpdate={onUpdate}
              setErrors={setErrors}
              errors={errors}
            />
          </Typography>
          {isActive && children}


        </AccordionDetails>
        
      </Accordion>
      {onDelete && <Modal open={showModal} onClose={handleModalClose}>
        <Box css={modalBoxStyle}>
          <div css={modalMain}>
            <p className="logout-model-header">
              Are you sure you want to delete?
            </p>
            <div css={buttonSection}>
              <CustomButton
                label="Cancel"
                onClick={handleModalClose}
                variant="outlined"
                css={regBackButton}
              />
              <CustomButton
                label="Delete"
                onClick={() => {
                  if (onDelete) {
                    onDelete();
                    handleModalClose();
                  }
                }}
                css={regNextButton}
              />
            </div>
          </div>
        </Box>
      </Modal>}
    </div>
  );
};

export default Accordian;
