/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import DashboardLoyout from "../../../components/DashbaordLayout/DashbaordLayout";
import CustomTable from "../../../components/Table/Table";
import { StringConstants } from "../../../constants/Strings";
import { VeirfyMainHeadding } from "../InitiateKyc/InitiateKyc.style";
import InitiateCpvDetails from "./InitiateCpvDetails";
import { get, post } from "../../../apiManger/apiMethods";
import { getApplicationDetailsGETApiUrl, getApplicationListPOSTApiUrl } from "../../../apiManger/apiConstants";
import { setLocalStorage } from "../../../utils/browserHandlers/browserStorageHandler";
import { formatDate } from "../InitiateKyc/InitiateKyc";
import Loder from "../../../components/Loader/Loder";
const columns = [
  { id: 'ApplicationNo', label: 'Application No', minWidth: 100 },
  { id: 'PartnerType', label: 'Partner Type', minWidth: 100 },
  { id: 'EntityType', label: 'Entity Type', minWidth: 100 },
  { id: 'PartnershipType', label: 'Partnership Type', minWidth: 100 },
  { id: 'Name', label: 'Name', minWidth: 100 },
  { id: 'MobileNo', label: 'Mobile No', minWidth: 100 },
  { id: 'Details', label: 'Details', minWidth: 100 },
];

const InitiateCpv = () => {
  const { VERIFY_CPV_HEADING } = StringConstants
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByStatus, setFilterByStatus] = useState("Pending");
  const [showDetails, setShowDetails] = useState(false);
  const [filters, setFilters] = useState(true);
  const [data, setData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detailsData, setDetailsData] = useState();
  const [filterByReg, setFilterByReg] = useState({
    startDate: "",
    endDate: "",
    partnerType: [],
    entityType: [],
    partnerShipType: []
  })
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  const [search, setSearch] = useState("")

  setLocalStorage('filterByStatus',filterByStatus)

  useEffect(() => {
    getAPICall();
  }, [page, rowsPerPage, filterByStatus, search, filterByReg]);

  const getAPICall = async () => {
    showLoadingIndicator()
    const reqObj = {
      startDate: filterByReg.startDate ? formatDate(filterByReg.startDate) : "",
      endDate: filterByReg.endDate ? formatDate(filterByReg.endDate) : "",
      pageNo: page,
      pageSize: rowsPerPage,
      search: search,
      partnerTypeValue: filterByReg.partnerType,
      entityTypeValue: filterByReg.entityType,
      partnershipTypeValue: filterByReg.partnerShipType,
      status:filterByStatus,
      stage:'fcucpv'
    };
    const response = await post({
      url: getApplicationListPOSTApiUrl(),
      obj: reqObj
    });

    if (response?.result && response?.result?.data.length) {
      hideLoadingIndicator()
      const array = response.result.data.map((item: any) => ({
       ApplicationNo: item.applicationNumber|| '',
        PartnerType: item.partnerSelection,
        EntityType: item.entityType,
        PartnershipType: item.partnershipType,
        Name: item.name || '',
        MobileNo: item.mobile,
        registrationId: item.id
      }));

      setData(array);
      setTotalRecords(response?.result?.totalRecords);
    } else {
      hideLoadingIndicator()
      setData([]);
      setTotalRecords(0);
    }
  };
  const handleviewDetails = (rowData: any) => {
    fetchDetails(rowData)

  }
  const fetchDetails = async (rowData: any) => {
    showLoadingIndicator();
    const response = await get({
      url: getApplicationDetailsGETApiUrl(rowData.registrationId),

    });
    if (response?.result) {
      hideLoadingIndicator();
      setLocalStorage("individual_id", response.result?.data?.individualDetails?.id)
      setLocalStorage('Application_registrationId',rowData.registrationId)

      setDetailsData(response.result)
      setShowDetails(rowData);
    }
    else{
      hideLoadingIndicator();
    }
 
  }
  const isMobile = window.innerWidth < 768;
  return (
    <div>

      
      {!showDetails ?
        <DashboardLoyout>
          <div css={VeirfyMainHeadding}>{VERIFY_CPV_HEADING}</div>
          <CustomTable columns={columns} data={data} handleviewDetails={handleviewDetails} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setFilterByStatus={setFilterByStatus}
            filterByStatus={filterByStatus}
            filters={filters}
            filterByReg={filterByReg}
            setFilterByReg={setFilterByReg}
            setSearch={setSearch}
            search={search} setRowsPerPage={setRowsPerPage}
            totalRecords={totalRecords}
            visible={visible}
            loadingComponent={loadingComponent}
          />
        </DashboardLoyout>
        : (
          <>
            {!isMobile && (
              <DashboardLoyout>
                  <div css={VeirfyMainHeadding}>{VERIFY_CPV_HEADING}</div>
                <InitiateCpvDetails showDetails={showDetails} detailsData={detailsData} setShowDetails={setShowDetails} filterByStatus={filterByStatus}/>
              </DashboardLoyout>
            )}
            {isMobile && (
              <InitiateCpvDetails showDetails={showDetails} detailsData={detailsData} setShowDetails={setShowDetails} filterByStatus={filterByStatus}/>
            )}
          </>
        )}
        
    </div >
  );
};

export default InitiateCpv;
