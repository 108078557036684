/** @jsxImportSource @emotion/react */
import { useState } from "react";
import CustomSelect from "../../../components/Select/Select";
import { regBackButton, regNextButton, regNextButtonDisabled,  registrationButtonContainer } from "../../Registration/Registration.style";
import { utilsConstants } from "../../../utils/utilsConstants";
import { StringConstants } from "../../../constants/Strings";
import CustomButton from "../../../components/Button/Button";
import { getLocalStorage, setLocalStorage } from "../../../utils/browserHandlers/browserStorageHandler";
import { Box, Grid } from '@mui/material';
import { css } from '@emotion/react';

const RegistrationDetails = (props: any) => {
    const { setValue,details,setShowDetails } = props;
    const [regSelectedOptions, setRegSelectedOptions] = useState(details?.registrationJourney);
    const [partnerSelectedOptions, setPartnerSelectedOptions] = useState(details?.partnerSelection);
    const [entitySelectedOptions, setEnitySelectedOptions] = useState(details?.entityType);
    const [partnershipSelectedOptions, setPartnershipSelectedOptions] = useState(details?.partnershipType);
    const { RegisterTypeOptions, PartnerTypeOptions, PartnerShipTypeOptions, EntityTypeOptions } = utilsConstants;
    const { SELECT_PLACEHOLDER, REGISTER_TYPE, PARTNER_TYPE, PARTNER_LABEL, REG_LABEL, ENTITY_TYPE, ENTITY_LABEL, PARTNERSHIP_TYPE, BACK_LABEL, NEXT_LABEL } = StringConstants;
setLocalStorage('Application_EntityType',details?.entityType)
setLocalStorage('Application_PartnershipType',details?.partnershipType)
const filterByStatus=getLocalStorage('filterByStatus');
const handleSelectChange = (event: any, type: any) => {
    const value = event.target.value;
    if (type === REGISTER_TYPE) {
      setRegSelectedOptions(value);
      setPartnerSelectedOptions("")
      setEnitySelectedOptions("")
    } else if (type === PARTNER_TYPE) {
      setPartnerSelectedOptions(value);
    } else if (type === ENTITY_TYPE) {
      setEnitySelectedOptions(value);
    } else if (type === PARTNERSHIP_TYPE) {
      setPartnershipSelectedOptions(value)
    }
  };
  const handleNext = async () => {
    setValue(1)
  }
  const gridContainerStyle = css`
    width: calc(100% + 30px);
    @media(max-width:768px){
  width: calc(100% + 20px);
 }
    `;
    const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;
    return (
        <div>
            {/* <div css={regiterTypesSection}> */}
            <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                <Grid container spacing={2} css={gridContainerStyle}>
                <Grid item xs={12} sm={6} md={3} >
                <CustomSelect options={RegisterTypeOptions}
                    placeholder={SELECT_PLACEHOLDER}
                    value={regSelectedOptions}
                    onChange={(e) => handleSelectChange(e, REGISTER_TYPE)}
                    inputLabel={REG_LABEL}
                    mandatory={true}
                    css={formControlStyle}
                    disabled
                />
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                <CustomSelect options={PartnerTypeOptions}
                    placeholder={SELECT_PLACEHOLDER}
                    value={partnerSelectedOptions}
                    onChange={(e) => handleSelectChange(e, PARTNER_TYPE)}
                    inputLabel={PARTNER_LABEL}
                    mandatory={true}
                    css={formControlStyle}
                    disabled
                />
                   </Grid>
 <Grid item xs={12} sm={6} md={3} >
                <CustomSelect options={EntityTypeOptions}
                    placeholder={SELECT_PLACEHOLDER}
                    value={entitySelectedOptions}
                    onChange={(e) => handleSelectChange(e, ENTITY_TYPE)}
                    inputLabel={ENTITY_LABEL}
                    mandatory={true}
                    css={formControlStyle}
                    disabled
                />
                   </Grid>
              {entitySelectedOptions==="Business Entity" &&
               <Grid item xs={12} sm={6} md={3} >
              <CustomSelect options={PartnerShipTypeOptions}
                    placeholder={SELECT_PLACEHOLDER}
                    value={partnershipSelectedOptions}
                    onChange={(e) => handleSelectChange(e, PARTNERSHIP_TYPE)}
                    inputLabel="Partnership Type"
                    mandatory={true}
                    css={formControlStyle}
                    disabled
                />
                   </Grid>
                }

                  </Grid>
                  </Box>
            <div css={registrationButtonContainer}>
                <CustomButton
                    label={BACK_LABEL}
                    variant="outlined"
                    css={regBackButton}
                    onClick={() => setShowDetails(false)}
                />
                
                <CustomButton
                    label={NEXT_LABEL}
                    onClick={() =>  handleNext()}
                    css={((entitySelectedOptions.includes('Business') ? (regSelectedOptions && partnerSelectedOptions && entitySelectedOptions && partnershipSelectedOptions ):(regSelectedOptions && partnerSelectedOptions && entitySelectedOptions) ) && filterByStatus?.toUpperCase()!==StringConstants.APPROVED_STATE)? regNextButton : regNextButtonDisabled}

                />
            </div>
        </div>
    )
}
export default RegistrationDetails;