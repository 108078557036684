/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import DashboardLoyout from "../../components/DashbaordLayout/DashbaordLayout";
import { formatDate } from "../ApplicationReview/InitiateKyc/InitiateKyc";
import { get, post } from "../../apiManger/apiMethods";
import { getApplicationDetailsGETApiUrl, getApplicationListPOSTApiUrl } from "../../apiManger/apiConstants";
import { setLocalStorage } from "../../utils/browserHandlers/browserStorageHandler";
import { payoutMainSection, VeirfyMainHeadding } from "../ApplicationReview/InitiateKyc/InitiateKyc.style";
import CustomTable from "../../components/Table/Table";
import EmpanelmentDetails from "./EmpanelmentDetails";
import Loder from "../../components/Loader/Loder";

const Empanelment = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByStatus, setFilterByStatus] = useState("Pending")
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  const columns = [
    { id: 'ApplicationNo', label: 'Application No', minWidth: 80 },
    { id: 'PartnerType', label: 'Partner Type', minWidth: 80 },
    { id: 'EntityType', label: 'Entity Type', minWidth: 80 },
    { id: 'PartnershipType', label: 'Partnership Type', minWidth: 80 },
    { id: 'Name', label: 'Name', minWidth: 80 },
    { id: 'MobileNo', label: 'Mobile No', minWidth: 80 },
    { id: 'Add', label:"Action" ,empanelmentItem:filterByStatus === "Approved"? 'Veiw Empanelment': 'Add Empanelment', minWidth: 120 },
  ];
  
  const [showDetails, setShowDetails] = useState(false)
  const [filters, setFilters] = useState(true)
  const [search, setSearch] = useState("")
  const [filterByReg, setFilterByReg] = useState({
    startDate: "",
    endDate: "",
    partnerType: [],
    entityType: [],
    partnerShipType: []
  })
  const [data, setData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detailsData, setDetailsData] = useState();
  useEffect(() => {
    getAPICall();
  }, [page, rowsPerPage, filterByStatus, search, filterByReg]);

  const getAPICall = async () => {
    showLoadingIndicator()

    const reqObj = {
      startDate: filterByReg.startDate ? formatDate(filterByReg.startDate) : "",
      endDate: filterByReg.endDate ? formatDate(filterByReg.endDate) : "",
      pageNo: page,
      pageSize: rowsPerPage,
      search: search,
      partnerTypeValue: filterByReg.partnerType,
      entityTypeValue: filterByReg.entityType,
      partnershipTypeValue: filterByReg.partnerShipType,
      status:filterByStatus,
      stage:'empanelment'
    };
    const response = await post({
      url: getApplicationListPOSTApiUrl(),
      obj: reqObj
    });

    if (response?.result && response?.result?.data.length) {
      hideLoadingIndicator();
      const array = response.result.data.map((item: any) => ({
       ApplicationNo: item.applicationNumber|| '',
        PartnerType: item.partnerSelection,
        EntityType: item.entityType,
        PartnershipType: item.partnershipType,
        Name: item.name || '',
        MobileNo: item.mobile,
        registrationId: item.id
      }));

      setData(array);
      setTotalRecords(response?.result?.totalRecords);
    } else {
      hideLoadingIndicator();
      setData([]);
      setTotalRecords(0);
    }
  };
  const handleviewDetails = (rowData: any) => {
    fetchDetails(rowData)

  }

  setLocalStorage('filterByStatus',filterByStatus)

  const fetchDetails = async (rowData: any) => {
    showLoadingIndicator();
    const response = await get({
      url: getApplicationDetailsGETApiUrl(rowData.registrationId),

    });
    if (response?.result) {
      hideLoadingIndicator();
      setLocalStorage('Application_registrationId',rowData.registrationId)
      setDetailsData(response.result)
      setShowDetails(rowData);
    }
  
  }
  return (
    <div>
     <DashboardLoyout>
        <div css={payoutMainSection}>
          <div css={VeirfyMainHeadding}>Empanelment</div>
        </div>
        {!showDetails ?
          <CustomTable
            columns={columns}
            data={data}
            handleviewDetails={handleviewDetails}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setFilterByStatus={setFilterByStatus}
            filterByStatus={filterByStatus}
            filters={filters}
            filterByReg={filterByReg}
            setFilterByReg={setFilterByReg}
            setSearch={setSearch}
            search={search}
            setRowsPerPage={setRowsPerPage}
            totalRecords={totalRecords}
            visible={visible}
            loadingComponent={loadingComponent}
          />
          :
           <EmpanelmentDetails showDetails={showDetails} detailsData={detailsData} setShowDetails={setShowDetails} setFilterByStatus={setFilterByStatus}/>
          

        }
      </DashboardLoyout>
    </div>
  );
};

export default Empanelment;
