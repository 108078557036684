/** @jsxImportSource @emotion/react */
import {  useState } from "react";
import TabsComponent from "../../components/TabsComponent/TabsComponent";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { InitiateKycDetailsSection } from "../ApplicationReview/InitiateKyc/InitiateKyc.style";
import CustomInput from "../../components/Input/Input";
import { regBackButton, registrationButtonContainer, regNextButton, regNextButtonDisabled } from "../Registration/Registration.style";
import CustomButton from "../../components/Button/Button";
import { dymanicWhiteSpaceValid, whiteSpaceValid } from "../../constants/Validations";
import { useToastHook } from "../../components/Toast/useToastHook";
import { ToastContainer } from "react-toastify";
import { empanelmentPOSTApiUrl } from "../../apiManger/apiConstants";
import { post } from "../../apiManger/apiMethods";
import { getLocalStorage } from "../../utils/browserHandlers/browserStorageHandler";
import Loder from "../../components/Loader/Loder";
import { StringConstants } from "../../constants/Strings";

const EmpanelmentDetails = (props: any) => {
  const {showToast} = useToastHook()
  const { showDetails ,detailsData,setShowDetails,setFilterByStatus} = props;
  const [value, setValue] = useState(0);
  const [inputValue,setInputValue] = useState("");
  const [error,setError] = useState<any>(null)
  const breadcrumbItems = [
    { label: 'Empanelment', href: '/empanelment' },
    { label: showDetails.ApplicationNo }
  ]
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
} = Loder();
  const handleInputChange = (e:any) => {
    const value = e.target.value;
    setInputValue(value)
    const showError =  whiteSpaceValid(value) 
    setError(showError)
  }
  const handleNext = async () =>{
    showLoadingIndicator();
    let reqObj={
      "registrationId":getLocalStorage('Application_registrationId'),
    "empanelmentCode":inputValue
    }
    try {
      let response;
      
          response = await post({
              url: empanelmentPOSTApiUrl(),
              obj: reqObj
          });
      
     
      if (response?.result) {
          hideLoadingIndicator();
          showToast("Submitted Successfully","success")
          setShowDetails(false)
          setFilterByStatus("Approved")
          }
      
      else {
          showToast("something went wrong","error")
          hideLoadingIndicator();
      }

  } catch (error) {
      showToast("something went wrong","error")
      hideLoadingIndicator();
      console.error("Failed to submit individual details:", error);
  }
  }
  const filterByStatus=getLocalStorage('filterByStatus')

  const tabs = [
    {
      label: 'Empanelment',
      content: (
        <div>
        <div>
         <CustomInput
                value={inputValue || detailsData?.data?.registrationDetails?.empanelmentCode}
                  onChange={(e) => handleInputChange(e)}
                  type="text"
                validate={(value) => dymanicWhiteSpaceValid(value,"Invalid input")}
                  required
                  placeholder={filterByStatus?.toUpperCase()!==StringConstants.APPROVED_STATE ? "Enter details":''}
                  inputLabel="Add Empanelment"
                  mandatory
                  disabled={filterByStatus?.toUpperCase()===StringConstants.APPROVED_STATE}
                />
        </div>
         <div css={registrationButtonContainer}>
         <CustomButton
             label="Back"
             onClick={() => setShowDetails(false)}
             variant="outlined"
             css={regBackButton}
         />
         <CustomButton
             label="Submit"
             onClick={() => handleNext()}
             css={(inputValue && error == null)  && filterByStatus?.toUpperCase()!==StringConstants.APPROVED_STATE? regNextButton:regNextButtonDisabled}
         />
     </div>
     </div>
      ),
    },
    
  ];

  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      {visible ? 
        <>{loadingComponent()}</> :
        <>    <div css={InitiateKycDetailsSection}>
        <TabsComponent tabs={tabs} value={value} setValue={setValue}/>
      </div>
      </>}
      <ToastContainer />
    </div>
  );
};

export default EmpanelmentDetails;
