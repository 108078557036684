/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

const Chart = ({ item, index }: any) => {
    const chartContainerStyle = css`
    height: auto;
    width: auto;
    max-width: 100%;

    @media (max-width: 768px) {
        width: 90vw;
    }
`;
    useEffect(() => {
        if (item) {
            setTimeout(() => {
                setChartData(item, index);
            }, 0);
        }
    }, [item, index]);

    return (
        <div
        id={`chart-container-${index}`}
        css={chartContainerStyle}
    >
        Loading chart...
    </div>
    );
}

function setChartData(item: any, index: number) {
    const { label, x_axis_label, y_axis_label, x_axis, y_axis } = item.render_config.chart;

    const xAxisCategories = item.data.map((dataObj: any) => dataObj[x_axis[0]] || 'Unknown');
    const seriesData = y_axis.map((nameValue: any) => ({
        name: nameValue,
        data: item.data.map((dataObj: any) => parseFloat(dataObj[nameValue]) || 0),
    }));

 
    const container = document.getElementById(`chart-container-${index}`);
    if (container) {
        Highcharts.chart(container, {
            chart: {
                type: item.render_config?.chart?.types[0], 
            },
            title: {
                text: label,
                style: {
                    color: '#333333',
                    fontWeight: '700',
                    fontSize: '16px',
                    textAlign: 'left',
                    display: 'block',
                    fontFamily: 'Inter',
                },
                margin: 40,
                align: 'left',
            },
            xAxis: {
                categories: xAxisCategories,
                title: {
                    text: x_axis_label || 'Product',
                    style: {
                        color: '#666666',
                        fontWeight: '700',
                        fontSize: '12px',
                        textAlign: 'left',
                        display: 'block',
                        fontFamily: 'Inter',
                    },
                },
                labels: {
                    rotation: -45, 
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Inter',
                    },
                },
            },
            yAxis: {
                title: {
                    text: y_axis_label.join(' & ') || 'Count',
                    style: {
                        color: '#666666',
                        fontWeight: '700',
                        fontSize: '12px',
                        textAlign: 'left',
                        display: 'block',
                        fontFamily: 'Inter',
                    },
                },
            },
            tooltip: {
                headerFormat: '<span style="font-size: 10px">{point.key} </span><br/>',
                valueSuffix: ' count',
                style: {
                    color: '#666666',
                    fontWeight: '700',
                    fontSize: '12px',
                    textAlign: 'left',
                    display: 'block',
                    fontFamily: 'Inter',
                },
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: seriesData,
        });
    } else {
        console.error('Container element not found!');
    }
}


export { Chart };
