/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { css, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { regBackButton, registrationButtonContainer, regNextButton, regNextButtonDisabled } from '../Registration/Registration.style';
import CustomButton from '../../components/Button/Button';
import { getEsignGETApiUrl, getEsignPostApiUrl, uploadFileApiUrl } from '../../apiManger/apiConstants';
import { useToastHook } from '../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import { get, post } from '../../apiManger/apiMethods';
import { getLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import Loder from '../../components/Loader/Loder';
import { useNavigate } from 'react-router-dom';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: '1px',
});

const UploadCustomButton = styled(Button)({
  width: '200px',
  height: '60px',
  color: '#e12c36',
  borderColor: '#e12c36',
  '&:hover': {
    borderColor: '#e12c36',
    backgroundColor: '#ffe6e6',
  },
});

const multifileSection = css`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
  width:100%;
  margin-bottom:30px;
`;

const fileListContainer = css`
width:70%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
`;

const fileItems = css`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  width: calc(50% - 30px); 
  margin-right: 10px; 
  justify-content:space-between;
  &:nth-of-type(2n) {
    margin-right: 0; 
  }
`;
const fileItem = css`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  width: calc(70% - ); 
  margin-right: 10px; 
  justify-content:space-between;
  &:nth-of-type(2n) {
    margin-right: 0; 
  }
`;
const fileName = css`
  margin-right: 8px;
`;
const buttonSection = css`
width:30%;
`
const MultiFileUpload = (props: any) => {
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();

  const { showToast } = useToastHook()
  const { setShowDetails, detailsData } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const navigate = useNavigate()
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file)
      fileUpload(file);
  };

  const fileUpload = async (file: File | null) => {
    if (!file) return;

    const data = new FormData();
    data.append("file", file);

    const response: any = await uploadFileApiUrl(data);

    if (response?.result?.[0]?.file_id) {
      const fileDetails = {
        fileId: response.result[0].file_id,
        fileName: response.result[0].file_name,
        fileType: response.result[0].content_type,
        fileSize: response.result[0].file_size,
        public_access: response.result[0].public_access,
        registrationId: getLocalStorage('Application_registrationId'),
        fileTypeId: '4ce76767-3999-4ceb-af72-a7ba96ac8f2d',
        status: 1,
        id: null
      };

      setUploadedFiles((prevFiles: any) => [...prevFiles, fileDetails]);
    } else if (response?.error?.message) {
      showToast(response.error.message, "error");
    } else {
      showToast("Something went wrong", "error");
    }
  };

  const handleDelete = (index: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  useEffect(() => {
    getAPICall()
  }, [])
  const registrationId = getLocalStorage('Application_registrationId');

  const getAPICall = async () => {
    showLoadingIndicator();
    const response: any = await get({
      url: getEsignGETApiUrl(registrationId)
    });
    if (response?.result) {
      hideLoadingIndicator();
      setUploadedFiles(response.result)
    }
    else {
      hideLoadingIndicator();
    }
  };
  const handleNext = async () => {
    showLoadingIndicator();
    try {
      let response;
      response = await post({
        url: getEsignPostApiUrl(registrationId),
        obj: uploadedFiles
      });
      if (response?.result) {
        if (response.result.screenName === 'empanelment_code') {
          navigate('/empanelment');
          hideLoadingIndicator();
        }
        else {
          showToast("Please retry after sometime", "error")
          hideLoadingIndicator()
        }
      }
      else {
        hideLoadingIndicator()
        showToast("something went wrong", "error")
      }
    }
    catch (error) {
      showToast("something went wrong", "error")

    }
  }
  return (
    <>
      {visible ? (
        <>{loadingComponent()}</>
      ) : (<>  <div css={multifileSection}>
        <div css={buttonSection}>
          <UploadCustomButton
            onClick={handleClick}
            variant="outlined"
            startIcon={
              <SvgIcon sx={{ color: '#e12c36' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>
              </SvgIcon>
            }
          >
            Upload file
          </UploadCustomButton>
          <VisuallyHiddenInput
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleFileUpload}
          />
        </div>
        <div css={fileListContainer}>
          {uploadedFiles.map((file: any, index: number) => (
            <div key={index} css={uploadedFiles?.length === 1 ? fileItem : fileItems}>
              <span css={fileName}>{file.fileName}</span>
              <IconButton size="small" onClick={() => handleDelete(index)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          ))}

        </div>
      </div>
        <div css={registrationButtonContainer}>
          <CustomButton
            label="Back"
            onClick={() => setShowDetails(false)}
            variant="outlined"
            css={regBackButton}
          />
          <CustomButton
            label="Next"
            onClick={() => handleNext()}
            css={uploadedFiles?.length || detailsData?.applicationProgress?.includes("e-signComplete") ? regNextButton : regNextButtonDisabled}
          />
          <ToastContainer />
        </div></>)}
    </>
  );
};

export default MultiFileUpload;
