/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import CustomInput from '../../components/Input/Input';
import { StringConstants } from '../../constants/Strings';
import { signInButtonStyle,inputStyle,descriptionStyle,titleStyle,containerStyle,welcomeSectionStyle,welcomeTitleStyle,welcomeTextStyle,loginSectionStyle,loginFormStyle, signInDisabledButtonStyle } from "./Login.style";
import LoginWithOtp from './LoginWithOtp';
import { dymanicWhiteSpaceValid, validEmail, whiteSpaceValid } from '../../constants/Validations';
import { post } from '../../apiManger/apiMethods';
import { signInApiUrl } from '../../apiManger/apiConstants';
import { useToastHook } from '../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import Loder from '../../components/Loader/Loder';
import { clearLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';

const LoginScreen = () => { 
  const {
  showLoadingIndicator,
  hideLoadingIndicator,
  loadingComponent,
  visible,
} = Loder();

  const {showToast} = useToastHook()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { LOGIN_STRING: { WELCOME, WELCOME_DESC,DESC,LOGIN, SIGN_IN,LABEL_PASSWORD } } = StringConstants;
  const [showOtpForm,setShowOtpForm] = useState(false)
  const [error ,setErrors] = useState<any>(null)
  const handleEmailChange = (e: any) => {
   const value = e.target.value
    setEmail(value);
   const error = validEmail(value)
  
   setErrors(error)
  };

  const handlePasswordChange = (e: any) => {
    const value = e.target.value
    setPassword(e.target.value.trim());
    const error = whiteSpaceValid(value)
    setErrors(error)
  };

  const handleSignIn = async() =>{
    showLoadingIndicator()
     const payload ={
      username :email,
      password:password
     }
    const response = await post({
      url: signInApiUrl(),
      obj: payload
  });

  if (response?.result) {
    hideLoadingIndicator()
    setShowOtpForm(true)
  }else if(response?.error?.message){
    hideLoadingIndicator()
    showToast(response?.error?.message,"error")
  }
else {
  hideLoadingIndicator()
    showToast("something went wrong","error")
}
  }
  useEffect(()=>{
    clearLocalStorage()
  },[])
  return (
    <div>
       {visible ? 
      <>{loadingComponent()}</> :
      <> 
      {!showOtpForm ?
    <div css={containerStyle}>
      <div css={welcomeSectionStyle}>
        <h1 css={welcomeTitleStyle}>{WELCOME}</h1>
        <p css={welcomeTextStyle}>
          {WELCOME_DESC}
        </p>
      </div>
      <div css={loginSectionStyle}>
        <div css={loginFormStyle}>
          <div css={titleStyle}>{LOGIN}</div>
          <div css={descriptionStyle}>{DESC}
          </div>
          <CustomInput
            css={inputStyle}
            type="text"
            inputLabel="User Name"
            value={email}
            onChange={handleEmailChange}
            info={true}
            validate={validEmail}
          />
          <CustomInput
            css={inputStyle}
            type="password"
            inputLabel={LABEL_PASSWORD}
            value={password}
            onChange={handlePasswordChange}
            info={true}
            validate={(value) => dymanicWhiteSpaceValid(value,"Invalid password")}
          />
          {/* <div css={optionsStyle}>
            <label>
              <input type="checkbox" />
              {REMEMBER_ME}
            </label>
            <a href="#" css={linkStyle}>{FORGOT}</a>
          </div> */}
          <button css={email && password && error == null ? signInButtonStyle : signInDisabledButtonStyle} onClick={() => handleSignIn()}>{SIGN_IN}</button>
          {/* <div css={footerStyle}>
            <p>{NO_ACC} <a href="/signup" css={linkStyle}>{FREE_ACC}</a></p>
          </div> */}
        </div>
      </div>
    </div>
      :
      <LoginWithOtp userName={email} password={password} setShowOtpForm={setShowOtpForm}/>
    }
    <ToastContainer />
    </>
       }
    </div>
  );
};

export default LoginScreen;
