/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import TabsComponent from "../../../components/TabsComponent/TabsComponent";
import { InitiateKycDetailsSection } from "./InitiateKyc.style"
import RegistrationDetails from "./RegistrationDetails";
import CompanyDetails from "./CompanyDetails";
import BankDetails from "./BankDetails";
import KycDocumentes from "./KycDetails";
import DirectorDetails from "./DirectorDetails";
import { getLocalStorage } from "../../../utils/browserHandlers/browserStorageHandler";
import IndividualDetails from "./IndividualDetails";
import { css } from "@emotion/react";
import { icon } from "../../../assets/Images";
import { colors, typographyInter } from "../../../scss/colors";
import { useNavigate } from "react-router-dom";
import { useToastHook } from "../../../components/Toast/useToastHook";
import { ToastContainer } from "react-toastify";
import Loder from "../../../components/Loader/Loder";
import Cibil from "./Cibil";
import IdVerification from "./IdVerification";

export const mobileNavigation = css`
display:none;
@media (max-width:768px){
display:flex;
margin-top:40px;
height:30px;
align-items:center;
gap:10%;
 color: ${colors.lightBlack};
   ${typographyInter('500', '16px')};
}
`
export const backCss = css`
display:none;
@media (max-width: 768px){
display:flex;
cursor:pointer;
}
`
const InitiateKycDetails = (props: any) => {
  const navigate = useNavigate()
  const { showDetails, detailsData, setShowDetails } = props;
  
  const [value, setValue] = useState(0);
  const { registrationDetails, bankDetails, individualDetails, companyDetails, directorDetails } = detailsData.data;
  const { showToast } = useToastHook();
  const breadcrumbItems = [
    { label: 'Application Review', href: '/initiate-kyc' },
    { label: 'Initiate KYC', subHref: '/initiate-kyc' },
    { label: showDetails.ApplicationNo }
  ];
  const {
    loadingComponent,
    visible,
} = Loder();
const filterByStatus=getLocalStorage('filterByStatus')
const handleNext = (screenName: string) => {
  if (screenName === "initiate_verification") {
    navigate("/initiate-kyc/initiate-cpv")
  } else {
    showToast("Please retry after sometime", "error")
  }
}

  const Individualtabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails
          setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}
            filterByStatus={filterByStatus}
          />

        </div>
      ),
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} details={individualDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId} contextId={showDetails?.ApplicationNo} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleKyc={handleNext} filterByStatus={filterByStatus}/></div> },
    
  ];

  const Proprietortabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails   setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails} filterByStatus={filterByStatus}/>
        </div>
      ),
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue}  filterByStatus={filterByStatus} details={companyDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} filterByStatus={filterByStatus} details={individualDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} filterByStatus={filterByStatus} details={bankDetails} registrationId={detailsData?.data?.registrationId} contextId={showDetails.ApplicationNo}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleKyc={handleNext} filterByStatus={filterByStatus}/></div> },
  ];
  const Businesstabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails    setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}
            filterByStatus={filterByStatus}
            />
        </div>
      ),
    },
    {
      label: 'Director Details',
      content:
        <div>
          <DirectorDetails setValue={setValue} details={directorDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue} details={companyDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId} contextId={showDetails.ApplicationNo} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleKyc={handleNext} filterByStatus={filterByStatus}/></div> },
  ];
  const [entityType, setEntityType] = useState('');
  const [partner, setPartner] = useState('');

  useEffect(() => {
    const fetchValues = () => {
      const latestEntityType = getLocalStorage('Application_EntityType');
      const latestPartner = getLocalStorage('Application_PartnershipType');
      setEntityType(latestEntityType);
      setPartner(latestPartner);
    };

    fetchValues();
  }, []);
  
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      {visible ? 
        <>{loadingComponent()}</> :
        <>      <div css={mobileNavigation}>
        <div css={backCss} onClick={() => setShowDetails(false)}>
          <img src={icon.NavigateBack} alt="no_navigate" />
        </div>
        <div>
          Initiate KYC - {showDetails.ApplicationNo}
        </div>
      </div>
      <div css={InitiateKycDetailsSection}>
        <TabsComponent
          tabs={entityType?.includes("Individual")
            ? Individualtabs
            : (entityType?.includes("Business") && partner?.includes("Proprietorship"))
              ? Proprietortabs
              : Businesstabs}
          value={value}
          setValue={setValue}
        />

      </div>
      <ToastContainer /></>}
    </div>
  );
};

export default InitiateKycDetails;
