/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import CustomInput from '../../components/Input/Input';
import { StringConstants } from '../../constants/Strings';
import { linkStyle,footerStyle,signInButtonStyle,optionsStyle,inputStyle,descriptionStyle,titleStyle,containerStyle,welcomeSectionStyle,welcomeTitleStyle,welcomeTextStyle,loginSectionStyle,loginFormStyle } from "../LoginScreen/Login.style";

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');

  const { SIGNUP_STRING: { WELCOME, WELCOME_DESC, FREE_ACC, NO_ACC, DESC, REMEMBER_ME, LOGIN, SIGN_IN,LABEL_FULLNAME, LABEL_EMAIL, LABEL_PASSWORD } } = StringConstants;

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleFullNameChange=(e:any)=>{
    setFullName(e.target.value);
  }

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  return (
    <div css={containerStyle}>
      <div css={welcomeSectionStyle}>
        <h1 css={welcomeTitleStyle}>{WELCOME}</h1>
        <p css={welcomeTextStyle}>
          {WELCOME_DESC}
        </p>
      </div>
      <div css={loginSectionStyle}>
        <div css={loginFormStyle}>
          <h2 css={titleStyle}>{LOGIN}</h2>
          <p css={descriptionStyle}>{DESC}
          </p>
          <CustomInput
            css={inputStyle}
            type="text"
            inputLabel={LABEL_FULLNAME}
            value={fullName}
            onChange={handleFullNameChange}
          />
          <CustomInput
            css={inputStyle}
            type="text"
            inputLabel={LABEL_EMAIL}
            value={email}
            onChange={handleEmailChange}
          />
          <CustomInput
            css={inputStyle}
            type="password"
            inputLabel={LABEL_PASSWORD}
            value={password}
            onChange={handlePasswordChange}
          />
          <div css={optionsStyle}>
            <label>
              <input type="checkbox" />
              {REMEMBER_ME}
            </label>
            {/* <a href="#" css={linkStyle}>{FORGOT}</a> */}
          </div>
          <button css={signInButtonStyle}>{SIGN_IN}</button>
          <div css={footerStyle}>
            <p>{NO_ACC} <a href="/" css={linkStyle}>{FREE_ACC}</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
