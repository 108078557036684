/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import TabsComponent from "../../../components/TabsComponent/TabsComponent";
import { InitiateKycDetailsSection } from "../InitiateKyc/InitiateKyc.style"
import RegistrationDetails from "../InitiateKyc/RegistrationDetails";
import CompanyDetails from "../InitiateKyc/CompanyDetails";
import BankDetails from "../InitiateKyc/BankDetails";
import KycDocumentes from "../InitiateKyc/KycDetails";
import { getLocalStorage } from "../../../utils/browserHandlers/browserStorageHandler";
import DirectorDetails from "../InitiateKyc/DirectorDetails";
import UploadCpv from "./UploadCpv";
import { backCss, mobileNavigation } from "../InitiateKyc/InitiateKycDetails";
import { icon } from "../../../assets/Images";
import { useNavigate } from "react-router-dom";
import IndividualDetails from "../InitiateKyc/IndividualDetails";
import Loder from "../../../components/Loader/Loder";
import IdVerification from "../InitiateKyc/IdVerification";
import Cibil from "../InitiateKyc/Cibil";
const InitiateCpvDetails = (props: any) => {
  const navigate = useNavigate()
  const { showDetails, detailsData, setShowDetails } = props;
  const [value, setValue] = useState(0);
  const [entityType, setEntityType] = useState('');
  const [partner, setPartner] = useState('');
  const breadcrumbItems = [
    { label: 'Application Review', href: '/initiate-kyc/initiate-cpv' },
    { label: 'Upload CPV/FCU', subHref: '/initiate-kyc/initiate-cpv' },
    { label: showDetails.ApplicationNo }
  ];
  const filterByStatus=getLocalStorage('filterByStatus')
  const {
    loadingComponent,
    visible,
  } = Loder();

  const handleNext = () => {
    setValue(value + 1)
  }
  const handleUploadNext = () => {
    navigate("/initiate-kyc/payout")
  }

  const { registrationDetails, bankDetails, individualDetails, companyDetails, directorDetails } = detailsData.data
  const Individualtabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails
            setValue={setValue}
            details={registrationDetails}
            setShowDetails={setShowDetails}
            filterByStatus={filterByStatus}
          />

        </div>
      ),
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} details={individualDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId} contextId={showDetails?.ApplicationNo} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleKyc={handleNext} filterByStatus={filterByStatus} /></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue}  value={value} handleUploadNext={handleUploadNext} filterByStatus={filterByStatus} /></div> },
  ];

  const Proprietortabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails setValue={setValue}
            details={registrationDetails}
            setShowDetails={setShowDetails} filterByStatus={filterByStatus} />
        </div>
      ),
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue} details={companyDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} details={individualDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId} contextId={showDetails?.ApplicationNo} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleKyc={handleNext} filterByStatus={filterByStatus} /></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue}  value={value} handleUploadNext={handleUploadNext} filterByStatus={filterByStatus} /></div> },
  ];
  const Businesstabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails setValue={setValue}
            details={registrationDetails}
            setShowDetails={setShowDetails}
            filterByStatus={filterByStatus}
          />
        </div>
      ),
    },
    {
      label: 'Director Details',
      content:
        <div>
          <DirectorDetails setValue={setValue} details={directorDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue} details={companyDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId} contextId={showDetails?.ApplicationNo} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus} />
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleKyc={handleNext} filterByStatus={filterByStatus} /></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} value={value} handleUploadNext={handleUploadNext} filterByStatus={filterByStatus} /></div> },
  ];

  useEffect(() => {
    const fetchValues = () => {
      const latestEntityType = getLocalStorage('Application_EntityType');
      const latestPartner = getLocalStorage('Application_PartnershipType');
      setEntityType(latestEntityType);
      setPartner(latestPartner);
    };

    fetchValues();
  }, []);
  return (
    <div>
      {visible ?
        <>{loadingComponent()}</> :
        <>    <Breadcrumb items={breadcrumbItems} />
          <div css={mobileNavigation}>
            <div css={backCss} onClick={() => setShowDetails(false)}>
              <img src={icon.NavigateBack} alt="no_navigate" />
            </div>
            <div>
              Initiate CPV/FCU - {showDetails.ApplicationNo}
            </div>
          </div>
          <div css={InitiateKycDetailsSection}>
            <TabsComponent tabs={entityType?.includes("Individual")
              ? Individualtabs
              : (entityType?.includes("Business") && partner?.includes("Proprietorship"))
                ? Proprietortabs
                : Businesstabs} value={value} setValue={setValue} />
          </div>
        </>}
    </div>
  );
};

export default InitiateCpvDetails;
