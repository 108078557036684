/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { getRenderData } from "../services/highcharts.service";
import { Chart } from "../../components/HighchartsChart/chart";
import { Table } from "../../components/HighchartsTable/table";
import { colors } from "../../scss/colors";
import { Card } from "../../components/HighchartsCards/card";
import Loder from "../../components/Loader/Loder";

const cardGridContainer = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CardContainer = css`
  width: 100%;
  display: flex;
  flex-grow: 1;
`;

const chartsTablesGridContainer = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr; 
  }
`;

const chartsLeftSection = css`
  border: 1px solid ${colors.stroke};
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
    border-radius: 10px;
  }
`;
const noDataContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 20px;
  color: ${colors.lightBlack};
`;
// const CardContainer = css`
// display: flex;
// justify-content: space-between;
// width: 100%;
// gap: 10px;

// @media (max-width: 768px) {
//   flex-wrap: wrap;
// }
// `;
const DashBoard = () => {
  const subscription = new Subscription();
  const [loading, setLoading] = useState<boolean>(true);
  const [renderData, setRenderData] = useState<any[]>([]);
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  useEffect(() => {
    getDashBoardData();
  }, []);


  const getDashBoardData = async () => {
    try {
      showLoadingIndicator();
      const data = await getRenderData();
      setRenderData(data); 
      setLoading(false); 
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setLoading(false);
    } finally {
      hideLoadingIndicator();
    }
  };

  return (
    <div style={{ width: '75vw' }}>
    {visible || loading? (
      <>{loadingComponent()}</>
    ) : renderData?.length === 0 ? (
      <div css={noDataContainer}>No Data Available</div>
    ) : (
      <>
        <div css={cardGridContainer}>
          {renderData?.length && renderData?.map((dataItem, index) => {
            const renderTypes = dataItem.render_config.render_types || [];
            return renderTypes.includes("card") ? (
              <div css={CardContainer} key={index}>
                <Card data={dataItem} />
              </div>
            ) : null;
          })}
        </div>

        <div css={chartsTablesGridContainer}>
          {renderData?.length && renderData?.map((dataItem, index) => {
            const renderTypes = dataItem.render_config.render_types || [];
            return (
              <React.Fragment key={index}>
                {renderTypes.includes("chart") && (
                  <div css={chartsLeftSection}>
                    <Chart item={dataItem} index={index} />
                  </div>
                )}
                {renderTypes.includes("table") && (
                  <div css={chartsLeftSection}>
                    <Table item={dataItem} />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </>
    )}
  </div>
);
};

export default DashBoard;
