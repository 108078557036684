/** @jsxImportSource @emotion/react */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import { SerializedStyles, css } from '@emotion/react';
import { colors, typography, typographyInter } from '../../scss/colors';
import { Pagination, PaginationItem } from '@mui/material';
import { StringConstants } from '../../constants/Strings';
import CustomSearch from '../SeachFeild/SearchFeild';
import Filters from './Filters';
import { icon } from '../../assets/Images';
import { TextField } from '@mui/material';
import { forwardRef, useState } from 'react';
import { regBackButton, regNextButton, regNextButtonDisabled } from '../../pages/Registration/Registration.style';
import CustomButton from '../Button/Button';
import CustomModal from '../Modal/Modal';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import EastIcon from '@mui/icons-material/East';
import { parse, format } from 'date-fns';
import MultiCheckBox from '../MultiCheckBox/MultiCheckBox';
import { utilsConstants } from '../../utils/utilsConstants';
import { getLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';

interface CustomTableProps {
    columns: Array<{ id: string, label: string, minWidth?: number }>;
    data: Array<any>;
    handleviewDetails?: any;
    page: number;
    rowsPerPage: number;
    setPage: any;
    setRowsPerPage?: any;
    setFilterByStatus?: any;
    filterByStatus?: any;
    filters?: boolean;
    filterByReg?: any;
    setFilterByReg?: any;
    setSearch?: any;
    search?: string;
    onlyTable?: boolean;
    totalRecords?: number;
    showPagination?: boolean;
    visible?:any;
    loadingComponent?:any;
}
const tableHeader = css`
    color: ${colors.lightBlack};
    ${typographyInter('500', '14px')};
     @media (max-width: 768px) {
        color: ${colors.placeholder};
    }
  `;

const tableBody = css`
        color: ${colors.tableLabel};
        ${typographyInter('500', '14px')};
    `;

const VeiwDetailsCss = css`
        color: ${colors.crimson};
        ${typographyInter('500', '14px')};
        text-decoration: underline;
        cursor: pointer;
    `;

const paginationStyles = css`
        .MuiPaginationItem-root.Mui-selected {
            color: ${colors.white};
            background-color: ${colors.crimson};
            border-radius: 6px;
            &:hover {
                color: ${colors.white};
                background-color: ${colors.crimson};
            }
        }
        .MuiPaginationItem-root {
            color: ${colors.tableFont};
            ${typographyInter('600', '14px')};
            border: none;
        }
    `;

const FilterContainer = css`
    display: flex;
    color: ${colors.tableLabel};
    ${typography('500', '14px')};
    border: 1px solid ${colors.stroke};
    border-radius:8px;
    height:42px;
    width:325px;
    align-items: center;
     @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
    }
  `;


const selectedFilter = css`
        background-color: ${colors.stroke};
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    `;

const filterCommon = css`
        height: 42px;
        flex: 1;
        align-items: center;
        cursor: pointer;
        display: flex;
        color: ${colors.tableLabel};
        ${typography('500', '14px')};
        border-right: 1px solid ${colors.stroke};
        justify-content: center;
        &:last-child {
            border-right: none;
        }
    `;

const filterStyles: { [key: string]: SerializedStyles } = {
    Pending: css`
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        `,
    Approved: css`
            border-radius: 0;
        `,
    Rejected: css`
            border-radius: 0;
        `,
    All: css`
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        `
};

const searchFilterSection = css`
    display:flex;
    width:100%;
    justify-content:space-between;
    margin: 20px 0px 30px 0px;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
    `

const searchCSS = css`
    width:40%;
     @media (max-width: 768px) {
     display:flex;
        width: 100%;
        margin-bottom: 20px;
        justify-content:space-between;
    }
    `
const mobileWidth = css`
     @media (max-width: 768px) {
      width:85%;
}
    `
const filterIcon = css`
    display:none;
    @media (max-width: 768px) {
     display:block;
     cursor:pointer;
}
   `

const FilterByReg = css`
    width:100%;
    @media (max-width: 768px) {
      display:none;
}
    `
const HeaderBack = css`
    background-color:${colors.tableHeader}
    `
const containerStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      display:none;
    }
`;
const MobileContainerStyles = css`
  display:none;
@media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
}
`;

export const FilterButtonContainer = css`
   display:none;
   @media (max-width: 768px) {
   display: flex;
   justify-content: center;
   align-items:center;
   gap: 20px;
    position: fixed;
    bottom: 0px; 
    left: 0;
    right: 0;
    border-top:1px solid ${colors.stroke};
    background-color: ${colors.white};
    z-index: 1000;
    height:64px;
  }
   `
const filterContainer = css`
width:375px;
 display:flex;
align-items: center;
  border-bottom: 1px solid ${colors.stroke};
padding: 10px 10px;
  `

const filterLabel = css`
  text-align: left;
  margin-bottom: 20px;
  color: ${colors.charcoal};
  ${typography('bold', '14px')};
  padding-left: 10px;
`
const filterBodySection = css`
`
const datePicker = css`
display:flex;
align-items:center;
gap:5px;
margin: 0px 20px 10px 20px;
`

const CustomTable: React.FC<CustomTableProps> = (props: any) => {
    const role = getLocalStorage("role")
    const { showPagination = true, columns, data, handleviewDetails, page, rowsPerPage, setPage, filterByStatus, setFilterByStatus, filters, filterByReg, setFilterByReg, setSearch, search, onlyTable, totalRecords,visible,loadingComponent } = props;
    const { PENDING, APPROVED, REJECTED, ALL } = StringConstants;
    const [showFilter, setShowFilter] = useState(false)
    const { PartnerTypeOptions, EntityTypeOptions, PartnerShipTypeOptions } = utilsConstants;
    const [tempFilterByReg, setTempFilterByReg] = useState({
        startDate: '',
        endDate: '',
        partnerType: [],
        entityType: [],
        partnerShipType: [],
    });
    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeFilter = (item: any) => {
        if (item === PENDING) {
            setFilterByStatus(PENDING);
        } else if (item === APPROVED) {
            setFilterByStatus(APPROVED);
        } else if (item === REJECTED) {
            setFilterByStatus(REJECTED);
        } else if (item === ALL) {
            setFilterByStatus(ALL);
        } else {
            setFilterByStatus("");
        }
    };
    const parseDate = (dateString: any) => {
        return dateString ? parse(dateString, 'dd.MM.yyyy', new Date()) : null;
    };
    const handleDateChange = (dateKey: 'startDate' | 'endDate') => (date: Date | null) => {
        setTempFilterByReg((prevState: any) => ({
            ...prevState,
            [dateKey]: date ? format(date, 'dd.MM.yyyy') : '',
        }));
    };
    const CustomDateInput = forwardRef(({ value, onClick, placeholder }: any, ref) => (
        <TextField
            value={value}
            onClick={onClick}
            placeholder={value ? '' : placeholder}
            inputRef={ref}
        />
    ));

    const handleSelectChange = (checkedItems: any, type: any) => {
        setTempFilterByReg((prevState) => ({
            ...prevState,
            [type]: checkedItems,
        }));
    };

    const applyFilters = () => {
        setFilterByReg(tempFilterByReg);
        setShowFilter(false)
    };
    const clearFilters = () => {
        setFilterByReg({
            startDate: "",
            endDate: "",
            partnerType: [],
            entityType: [],
            partnerShipType: []
        });
        setTempFilterByReg({
            startDate: "",
            endDate: "",
            partnerType: [],
            entityType: [],
            partnerShipType: []
        });
    };
    return (
        <div>

            <div>
                {!onlyTable && (
                    <div>
                        <div css={searchFilterSection}>
                            <div css={FilterContainer}>
                                {["Pending", "Approved", "Rejected"].map((status) => (
                                    <div
                                        key={status}
                                        onClick={() => handleChangeFilter(status)}
                                        css={[
                                            filterByStatus === status ? selectedFilter : "",
                                            filterCommon,
                                            filterStyles[status],
                                        ]}
                                    >
                                        {status}
                                    </div>
                                ))}
                            </div>
                            <div css={searchCSS}>
                                <div css={mobileWidth}>
                                    <CustomSearch setSearch={setSearch} search={search} setPage={setPage} /></div>
                                <div css={filterIcon} onClick={() => setShowFilter(true)}> <img src={icon.FilterIcon} alt="filter_icon" /></div>
                            </div>
                        </div>
                        {filters && (
                            <div css={FilterByReg}>
                                <Filters filterByReg={filterByReg}
                                    setFilterByReg={setFilterByReg} />
                            </div>
                        )}
                    </div>
                )}
                <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow css={HeaderBack}>
                                {columns.map((column: any) => (
                                    <TableCell key={column.id} style={{ minWidth: column.minWidth }} css={tableHeader}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visible ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        {loadingComponent()}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data?.length > 0 ?
                                data.map((row: any, rowIndex: any) => (
                                    <TableRow key={rowIndex}>
                                        {columns.map((column: any) => {
                                            return column.id === "Details" ? (
                                                <TableCell key={column.id} css={VeiwDetailsCss} onClick={() => handleviewDetails(row)}>
                                                    View Details
                                                </TableCell>
                                            ) :column.id === "Action" ? (
                                                <TableCell key={column.id} css={VeiwDetailsCss} onClick={() => handleviewDetails(row)}>
                                                   {column?.esignItem}
                                                </TableCell>
                                            ) :column.id === "Add" ? (
                                                <TableCell key={column.id} css={VeiwDetailsCss} onClick={() => handleviewDetails(row)}>
                                                 {column?.empanelmentItem}
                                                </TableCell>
                                            ): (
                                                <TableCell key={column.id} css={tableBody}>
                                                    {row[column.id]}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                )):(
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            No Data Available
                                        </TableCell>
                                    </TableRow>
                                )
                            )}

                            {/* {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={columns.length} />
        </TableRow>
    )} */}
                        </TableBody>

                        {showPagination && data?.length > 0 && (
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={columns.length} sx={{ borderBottom: 'none' }}>
                                        <Box css={containerStyles}>
                                            <Box css={tableHeader}>
                                                Showing {page * rowsPerPage + 1} to {Math.min((page + 1) * rowsPerPage, totalRecords)} of {totalRecords} entries
                                            </Box>
                                            <Box css={paginationStyles}>
                                                <Pagination
                                                    page={page + 1}
                                                    onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                                                    count={Math.ceil(totalRecords / rowsPerPage)}
                                                    variant="outlined"
                                                    shape="rounded"
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            components={{
                                                                previous: () => <span>Previous</span>,
                                                                next: () => <span>Next</span>,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>)}
                    </Table>
                </TableContainer>
                {showPagination && (<Box css={MobileContainerStyles}>

                    <Box css={paginationStyles}>
                        <Pagination
                            page={page + 1}
                            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                            count={Math.ceil(totalRecords / rowsPerPage)}
                            variant="outlined"
                            shape="rounded"
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    components={{
                                        previous: () => <span>Previous</span>,
                                        next: () => <span>Next</span>,
                                    }}

                                />
                            )}
                        />
                    </Box>
                    <Box css={tableHeader}>
                        Showing {page * rowsPerPage + 1} to {Math.min((page + 1) * rowsPerPage, data.length)} of {data.length} entries
                    </Box>
                </Box>
                )}

            </div>
            <CustomModal
                open={showFilter}
                onClose={() => setShowFilter(false)}
                header="Filter"
                isCenter={true}
            >
                <div css={filterContainer}>
                    <div css={filterBodySection}>
                        <div css={filterLabel}>Date Range</div>


                        <div css={datePicker}>
                            <DatePicker
                                selected={parseDate(tempFilterByReg?.startDate ?? filterByReg?.startDate)}
                                onChange={handleDateChange('startDate')}
                                dateFormat="dd.MM.yyyy"
                                placeholderText='DD.MM.YYYY'
                                customInput={<CustomDateInput placeholder="DD.MM.YYYY" />}
                            />
                            <EastIcon />
                            <DatePicker
                                selected={parseDate(tempFilterByReg?.endDate ??filterByReg?.endDate)}
                                onChange={handleDateChange('endDate')}
                                dateFormat="dd.MM.yyyy"
                                placeholderText='DD.MM.YYYY'
                                customInput={<CustomDateInput placeholder="DD.MM.YYYY" />}
                            />
                        </div>
                    </div>

                </div>
                {role?.includes("user") ?
                    <>
                        <div css={filterContainer}>
                            <div css={filterBodySection}>
                                <div css={filterLabel}>Location</div>
                                <div css={datePicker}>
                                    <MultiCheckBox
                                        options={[{ value: "Bangalore", label: "Bangalore" }]}
                                        onChange={(e) => handleSelectChange(e, 'partnerType')}
                                        value={filterByReg?.location?.length ? filterByReg?.location : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div css={filterContainer}>
                            <div css={filterBodySection}>
                                <div css={filterLabel}>Stage</div>
                                <div css={datePicker}>
                                    <MultiCheckBox
                                        options={[{ value: "1", label: "1" }]}
                                        onChange={(e) => handleSelectChange(e, 'entityType')}
                                        value={filterByReg?.range}
                                    />
                                </div>
                            </div>
                        </div>
                        <div css={filterContainer}>
                            <div css={filterBodySection}>
                                <div css={filterLabel}>Product</div>
                                <div css={datePicker}>
                                    <MultiCheckBox
                                        value={filterByReg?.product}
                                        options={[{ value: "Ev", label: "Ev" }]}
                                        onChange={(e) => handleSelectChange(e, 'partnerShipType')}
                                    />
                                </div>
                            </div>
                        </div>
                    </> :
                    <>
                        <div css={filterContainer}>
                            <div css={filterBodySection}>
                                <div css={filterLabel}>Partner Type</div>
                                <div css={datePicker}>
                                    <MultiCheckBox
                                        options={PartnerTypeOptions}
                                        onChange={(e) => handleSelectChange(e, 'partnerType')}
                                        value={filterByReg?.partnerType?.length ? filterByReg?.partnerType : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div css={filterContainer}>
                            <div css={filterBodySection}>
                                <div css={filterLabel}>Entity Type</div>
                                <div css={datePicker}>
                                    <MultiCheckBox
                                        options={EntityTypeOptions}
                                        onChange={(e) => handleSelectChange(e, 'entityType')}
                                        value={filterByReg?.entityType}
                                    />
                                </div>
                            </div>
                        </div>
                        <div css={filterContainer}>
                            <div css={filterBodySection}>
                                <div css={filterLabel}>Partnership Type</div>
                                <div css={datePicker}>
                                    <MultiCheckBox
                                        value={filterByReg?.partnerShipType}
                                        options={PartnerShipTypeOptions}
                                        onChange={(e) => handleSelectChange(e, 'partnerShipType')}
                                    />
                                </div>
                            </div>
                        </div>
                        
                    </>}
            </CustomModal>
            {showFilter && (
                <div css={FilterButtonContainer}>
                    <CustomButton
                        label="Clear All"
                        onClick={() => clearFilters()}
                        variant="outlined"
                        css={regBackButton}
                    />
                    <CustomButton
                        label="Apply"
                        onClick={applyFilters}
                        css={tempFilterByReg.startDate || tempFilterByReg.endDate || tempFilterByReg.entityType.length || tempFilterByReg.partnerShipType.length || tempFilterByReg.partnerType.length ? regNextButton : regNextButtonDisabled}

                    />
                </div>
            )}
        </div>)
};

export default CustomTable;
