/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { css } from '@emotion/react';
import React from 'react';
import CustomCard from "../Card/Card";
import { typographyInter, colors } from "../../scss/colors";

function Card(data: any) {
  const CardSection = css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  `;

  const CardTotalCount = css`
    color: ${colors.lightBlack};
    ${typographyInter('700', '20px')};
    word-wrap: break-word;
    overflow-wrap: break-word; 
    white-space: normal; 
    
    @media (max-width: 768px) {
      color: ${colors.crimson};
      ${typographyInter('700', '16px')};
    }
  `;

  const cardStyle = css`
    width: 100%; 
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 120px;
    max-width: 400px; 
    
    @media (max-width: 1024px) {
      max-width: 350px;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }
  `;

  useEffect(() => { }, []);
  const formatRupee = (amount: number) => {
    return `₹${amount.toLocaleString('en-IN')}`;
  };
  const formatNumber = (num: number) => {
    return num.toLocaleString('en-IN');
  };
  let displayValue = null;
  if (data.data.render_config.render_types[0] === "card") {
    const valueKey = data.data.render_config.card.value;
    displayValue = data.data.data[0][valueKey] || 0;
    if (data.data.render_config.card.label === "Amount" && !isNaN(Number(displayValue))) {
      displayValue = formatRupee(Number(displayValue));
    }
    else if (!isNaN(Number(displayValue))) {
      displayValue = formatNumber(Number(displayValue));
    }
  }
  return (
    <div css={cardStyle}>
      <CustomCard title={data.data.render_config.label} width="100%">
        <div css={CardSection}>
          <div css={CardTotalCount}>{displayValue}</div>
        </div>
      </CustomCard>
    </div>
  );
}

export { Card };
