/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import CustomInput from '../../../components/Input/Input';
import CustomButton from '../../../components/Button/Button';
import { css } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useToastHook } from '../../../components/Toast/useToastHook';
import Loder from '../../../components/Loader/Loder';
import { StringConstants } from '../../../constants/Strings';
import { ValidName, whiteSpaceValid } from '../../../constants/Validations';
import { get } from '../../../apiManger/apiMethods';
import { getCIBILGETApiUrl } from '../../../apiManger/apiConstants';
import { getLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../../Registration/Registration.style';
import CustomSelect from '../../../components/Select/Select';
export const registrationOtpContainer = css`
display: flex;
align-items: flex-start;
flex-wrap:wrap;
justify-content:space-between;`;

const Cibil = (props: any) => {
    const { showToast } = useToastHook();

    const { setValue,registrationId, value } = props;
    const filterByStatus=getLocalStorage('filterByStatus')
    const [individualDetails, setIndividialDetail] = useState<any>([]);

    const [errors, setErrors] = useState({});
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const { BACK_LABEL, NEXT_LABEL } = StringConstants;

    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();

    useEffect(() => {
        getAPICall();

    }, []);

    const getAPICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getCIBILGETApiUrl(registrationId),
        });
        if (response?.result) {
            hideLoadingIndicator();
            setIndividialDetail(response.result);
        } else {
            hideLoadingIndicator();
        }
    };

    const handleNext = async () => {
        showLoadingIndicator();


        try {
            hideLoadingIndicator();
            setValue(value + 1)
        } catch (error) {
            hideLoadingIndicator();
            showToast('Something went wrong', 'error');
        }
    };

    const handleBack = () => {
        setValue((pre: any) => pre - 1);
    };

    const handleChange = (fieldName: any, value: any) => {
        setIndividialDetail({
            ...individualDetails,
            [fieldName]: value,
        });
        const validatorFn = getValidator(fieldName);
        const error = validatorFn(value);
        setErrors({
            ...errors,
            [fieldName]: error,
        });
    };

    const getValidator = (fieldName: any) => {
        switch (fieldName) {
            case 'name':
                return ValidName;
            case 'gender':
                return whiteSpaceValid;
            case 'dateOfBirth':
            case 'controllerNo':
            case 'lastPullDateTime':
            case 'bureauScore':
            case 'personalScore':
                return whiteSpaceValid;
            default:
                return () => null;
        }
    };

    const gridContainerStyle = css`
        width: calc(100% + 30px);
        @media (max-width: 768px) {
            width: calc(100% + 20px);
        }
    `;
    const formControlStyle = css`
        margin-bottom: 16px;
        width: 100%;
    `;

    return (
        <div>
            {visible ? (
                <>{loadingComponent()}</>
            ) : (
                <>
                    {individualDetails?.map(((item: any, index: any) => {
                        return (
                            <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                                <Grid container spacing={2} css={gridContainerStyle}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <CustomInput
                                            inputLabel="Name"
                                            value={item.name}
                                            onChange={(e) => handleChange('name', e.target.value)}
                                            type="text"
                                            css={formControlStyle}
                                            required
                                            placeholder="Full Name"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <CustomSelect
                                            options={[
                                                { value: 'Male', label: 'Male' },
                                                { value: 'Female', label: 'Female' },
                                                { value: 'Others', label: 'Others' },
                                            ]}
                                            placeholder='Gender'
                                            value={item.gender}
                                            onChange={(e) => handleChange('gender', e.target.value)}
                                            inputLabel="Gender"
                                            css={formControlStyle}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <CustomInput
                                            inputLabel="Date of Birth"
                                            value={item.dateOfBirth}
                                            onChange={(e) => handleChange('dateOfBirth', e.target.value)}
                                            type="date"
                                            css={formControlStyle}
                                            required
                                            disabled
                                            placeholder='Date of Birth'

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <CustomInput
                                            inputLabel="Controller No."
                                            value={item.controllerNo}
                                            onChange={(e) => handleChange('controllerNo', e.target.value)}
                                            type="text"
                                            css={formControlStyle}
                                            required
                                            placeholder="Controller No."
                                            disabled

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <CustomInput
                                            inputLabel="Last Pull Date/Time"
                                            value={item.lastPullDateTime}
                                            onChange={(e) => handleChange('lastPullDateTime', e.target.value)}
                                            type="datetime-local"
                                            css={formControlStyle}
                                            required
                                            disabled
                                            placeholder='Last pull date/time'

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <CustomInput
                                            inputLabel="TransUnion Score"
                                            value={item.bureauScore}
                                            onChange={(e) => handleChange('bureauScore', e.target.value)}
                                            type="number"
                                            css={formControlStyle}
                                            required
                                            disabled
                                            placeholder='TransUnion Score'

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <CustomInput
                                            inputLabel="Personal Score"
                                            value={item.personalScore}
                                            onChange={(e) => handleChange('personalScore', e.target.value)}
                                            type="number"
                                            css={formControlStyle}
                                            required
                                            disabled
                                            placeholder='Personal score'

                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    }))}

                    <div css={registrationButtonContainer}>
                        <CustomButton
                            label={BACK_LABEL}
                            onClick={handleBack}
                            variant="outlined"
                            css={regBackButton}
                        />
                        <CustomButton
                            label={NEXT_LABEL}
                            onClick={handleNext}
                            css={(!isNextButtonDisabled && filterByStatus?.toUpperCase()!==StringConstants.APPROVED_STATE) ? regNextButton : regNextButtonDisabled}
                        />
                    </div>
                    <ToastContainer />
                </>
            )}
        </div>
    );
};

export default Cibil;
