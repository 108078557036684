/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import CustomButton from '../../../components/Button/Button';
import { colors, typography } from '../../../scss/colors';
import { css } from '@emotion/react';
import { utilsConstants } from '../../../utils/utilsConstants';
import { useLocation } from 'react-router-dom';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../../Registration/Registration.style';
import { StringConstants } from '../../../constants/Strings';
import InputFile from '../../../components/InputFile/InputFile';
import { getLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { getCompanyKYCGETApiUrl, getCompanyKYCPostApiUrl, getDirectorGETApiUrl, getIndividualGETApiUrl, getPartnerKYCGETApiUrl, getPartnerKYCPostApiUrl, uploadFileApiUrl } from '../../../apiManger/apiConstants';
import { get, post } from '../../../apiManger/apiMethods';
import Loder from '../../../components/Loader/Loder';
import { Box, Grid } from '@mui/material';
import { useToastHook } from '../../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import PreviewComponent from '../../KycDocumentes/Preview';

const kycSubHeading = css`
color: ${colors.charcoal};
${typography('600', '16px')}
display:flex;
justify-content:flex-start;
margin-bottom:20px;
`

export const previewSection = css`
color: ${colors.buttonOrange};
${typography('700', '16px')}
text-decoration:line;
text-decoration: underline;
display: flex;
margin-bottom:20px;
cursor:pointer;
`

const KycDocumentes = (props: any) => {
    const { setValue, handleKyc } = props;
    const filterByStatus = getLocalStorage('filterByStatus')
    const { STEPPER, STEPPER_INDIVIDUAL, STEPPER_INDIVIDUAL_DIRECTOR, RegisterTypeOptions, EntityTypeOptions, PartnerShipTypeOptions } = utilsConstants;
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();
    const { BACK_LABEL, KYC_VERIFICATION_COMPANY_HEADING, KYC_VERIFICATION_HEADING } = StringConstants;
    const [errors, setErrors] = useState<any>({});
    const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
    const [companyUploadedDoc, setCompanyUploadedDoc] = useState<any>({});
    const [directors, setDirectors] = useState<any>([]);
    const entityType=getLocalStorage('Application_EntityType');
    const partnershipTypeLS=getLocalStorage('Application_PartnershipType');
    const [documents, setDocuments] = useState<any>({
        pan: { fileId: "", fileName: "" },
        aadhar: { fileId: "", fileName: "" },
        voter: { fileId: "", fileName: "" },
        driving: { fileId: "", fileName: "" },
        passport: { fileId: "", fileName: "" },
        additional1: { fileId: "", fileName: "" },
        additional2: { fileId: "", fileName: "" },
        business: { fileId: "", fileName: "" },
        gst: { fileId: "", fileName: "" },
        udyam: { fileId: "", fileName: "" },
        additional3: { fileId: "", fileName: "" },
        additional4: { fileId: "", fileName: "" }
    });
    const docList = [
        { inputLabel: "PAN Card", name: "pan", id: '1ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileUrl: '', fileId: '' ,mandatory:true},
        { inputLabel: "Aadhaar Card", name: "aadhar", id: '2ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileUrl: '', fileId: '',mandatory:true },
        { inputLabel: "Voter ID", name: "voter", id: '3ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileUrl: '', fileId: '' },
        { inputLabel: "Driving License", name: "driving", id: "4ce76767-3999-4ceb-af72-a7ba96ac8f2d", value: '', fileUrl: '', fileId: '' }
    ];

    const docList2 = [
        { inputLabel: "Passport", name: "passport", id: '5ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileUrl: '', fileId: '' },
        { inputLabel: "Additional Document", name: "additional1", id: '41d6e7c5-9431-4993-86fa-fa6deb01e5f1', value: '', fileUrl: '', fileId: '' },
        { inputLabel: "Additional Document", name: "additional2", id: '10662581-4f7b-4c0d-918c-d937e1c9cf51', value: '', fileUrl: '', fileId: '' }
    ];

    const companydocList = [
        { inputLabel: "Business PAN", name: "business", id: "c98bf386-ec7a-43f7-a7b3-bed71ec28ff8", value: '', fileUrl: '', fileId: '',mandatory:(entityType === 'Individual' || entityType?.includes("Sole")) ? false:true },
        { inputLabel: "GST", name: "gst", id: 'fd542557-09bf-4568-b20a-3d0bedc2c0d8', value: '', fileUrl: '', fileId: '',mandatory:entityType === 'Individual' ? false:true },
        { inputLabel: "Udyam Aadhaar", name: "udyam", id: '15032b4c-5f45-47f7-8ec0-f1479af27a69', value: '', fileUrl: '', fileId: '' },
        { inputLabel: "Partnership Deed", name: "deed", id: '11b3b123-c005-4b5b-b2f2-716051da27f6', value: '', fileId: '' ,mandatory:(partnershipTypeLS === 'Partnership' || partnershipTypeLS === 'LLP')? true:false },
    { inputLabel: "MoA", name: "moa", id: '12b3b123-c005-4b5b-b2f2-716051da27f6', value: '', fileId: '' ,mandatory:(partnershipTypeLS === 'Private Ltd' || partnershipTypeLS === 'Public Ltd')? true:false },
    { inputLabel: "AoA", name: "aoa", id: '13b3b123-c005-4b5b-b2f2-716051da27f6', value: '', fileId: '' ,mandatory:(partnershipTypeLS === 'Private Ltd' || partnershipTypeLS === 'Public Ltd')? true:false },
    { inputLabel: "Board Resolution", name: "board", id: '14b3b123-c005-4b5b-b2f2-716051da27f6', value: '', fileId: '' ,mandatory:(entityType === 'Individual' || entityType?.includes("Sole")) ? false:true  },
        { inputLabel: "Additional Document", name: "additional3", value: '', fileUrl: '', fileId: '', id: '41d6e7c5-9431-4993-86fa-fa6deb01e5f1' },
        { inputLabel: "Additional Document", name: "additional4", value: '', fileUrl: '', fileId: '', id: '10662581-4f7b-4c0d-918c-d937e1c9cf51' }
    ];
    const { showToast } = useToastHook()
    const [companydocListState, setCompanyDocListState] = useState(companydocList);
    const registrationId = getLocalStorage('Application_registrationId');
    const { state } = useLocation();
    const [stepper, setStepper] = useState(STEPPER)
    useEffect(() => {
        const checkMandatoryFilesUploaded = () => {
          let allFilesUploaded = true;
      
          directors.forEach((_:any, directorIndex:any) => {
            docList.forEach((doc) => {
              if (doc.mandatory && (!uploadedDocuments[directorIndex] || !uploadedDocuments[directorIndex][doc.name]?.fileId)) {
                allFilesUploaded = false;
              }
            });
      
            docList2.forEach((doc:any) => {
              if (doc.mandatory && (!uploadedDocuments[directorIndex] || !uploadedDocuments[directorIndex][doc.name]?.fileId)) {
                allFilesUploaded = false;
              }
            });
          });
      
          companydocListState.forEach((doc) => {
            if (doc.mandatory && (!companyUploadedDoc[doc.name]?.fileId && !doc.value)) {
              allFilesUploaded = false;
            }
          });
          return allFilesUploaded;
        };

          setIsNextButtonDisabled(!checkMandatoryFilesUploaded());
      }, [uploadedDocuments, companyUploadedDoc, directors, docList, docList2, companydocListState,isNextButtonDisabled]);
      
    useEffect(() => {
        getAPICall();
        if (getLocalStorage('Application_EntityType') === "Business Entity") {
            getCompanyAPICall();
        }
    }, []);


    const individual_id = getLocalStorage('individual_id');
    useEffect(() => {
        const entity = getLocalStorage('Application_EntityType');
        if (entity === "Business Entity" && getLocalStorage('Application_PartnershipType') !== "Sole Proprietorship") {
            getDIRAPICall();
        }
        else {
            if (individual_id) {
                getIndividual()
            }
        }

    }, []);

    const getIndividual = async () => {
        showLoadingIndicator()
        const response = await get({
            url: getIndividualGETApiUrl(registrationId, individual_id)
        });
        if (response?.result) {
            hideLoadingIndicator();
            const { firstName, lastName } = response.result
            const defaultDirector: any = {
                directorId: getLocalStorage('individual_id'),
                firstName: firstName,
                lastName: lastName
            };
            setDirectors([defaultDirector]);
        }
        else {
            hideLoadingIndicator();
        }
    }


    useEffect(() => {
        if (directors?.length > 0) {
            showLoadingIndicator();
            getAPICall();
            if (getLocalStorage('Application_EntityType') === "Business Entity") {
                getCompanyAPICall();
            }
        }
    }, [directors]);
    const getDIRAPICall = async () => {
        const response = await get({ url: getDirectorGETApiUrl(registrationId) });
        if (response?.result) {
            hideLoadingIndicator();
            const fetchedDirectors = response?.result?.length && response.result.map((director: any) => ({
                directorId: director.id,
                firstName: director.firstName || 'New Director',
                lastName: director.lastName,
            }));
            setDirectors(fetchedDirectors);
        } else {
            hideLoadingIndicator();
            showToast('Failed to load director information', 'error');
        }
    };



    const getAPICall = async () => {
        const response = await get({
            url: getPartnerKYCGETApiUrl(registrationId),
        });
        if (response?.result) {
            hideLoadingIndicator();

            const directorDocsMap: any = {};

            response.result.forEach((doc: any) => {
                const { directorId, fileTypeId, fileName, id, fileUrl, fileId } = doc;
                if (!directorDocsMap[directorId]) {
                    directorDocsMap[directorId] = {};
                }
                directorDocsMap[directorId][fileTypeId] = {
                    fileName,
                    documentId: id,
                    fileUrl,
                    fileId,
                    id: id || null
                };
            });

            const updatedUploadedDocuments = directors?.length && directors.map((director: any) => directorDocsMap[director.directorId] || {});
            setUploadedDocuments(updatedUploadedDocuments);
        } else {
            hideLoadingIndicator();
            showToast('No documents found or failed to load data', 'error');
        }
    };

    const getCompanyAPICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getCompanyKYCGETApiUrl(registrationId)
        });

        if (response?.result) {
            hideLoadingIndicator();
            const updatedCompanyDocList = companydocList.map((item) => {
                const resItem = response?.result && response?.result?.find((res: any) => res.fileTypeId === item.id);
                return resItem ? { ...item, value: resItem.fileName, documentId: resItem.id, fileUrl: resItem.fileUrl, fileId: resItem.fileId } : item;
            });
            setCompanyDocListState(updatedCompanyDocList);
        }
        else {
            hideLoadingIndicator();
        }
    };


    const handleNext = async () => {
        showLoadingIndicator();
        try {
            const directorsPayload = directors.map((director: any, directorIndex: any) => {
                const kycDocuments = Object.entries(uploadedDocuments[directorIndex] || {})
                    .filter(([_, doc]: any) => doc.fileId)
                    .map(([docName, doc]: any) => ({
                        fileTypeId: docName,
                        fileId: doc.fileId,
                        fileName: doc.fileName,
                        fileSize: doc?.fileSize,
                        fileType: doc?.fileType,
                        status: 1,
                        id: doc?.id
                    }));

                return {
                    directorId: director.directorId,
                    kyc: kycDocuments 
                };
            });
            let obj = {
                "registrationId": getLocalStorage("Application_registrationId"),
                "directors": directorsPayload
            }

            const response = await post({
                url: getPartnerKYCPostApiUrl(getLocalStorage("Application_registrationId")),
                obj: obj
            });

            if (response?.result) {
                if (getLocalStorage('Application_EntityType') === "Business Entity") {
                    showLoadingIndicator();
                    try {
                        const companyDocuments = Object.values(companyUploadedDoc).filter((doc: any) => doc !== null);
                        const companyloadItems = companyDocuments.map((item: any) =>
                        ({
                            fileTypeId: item.fileTypeId,
                            fileId: item.fileId,
                            fileSize: item?.fileSize,
                            fileType: item?.fileType,
                            fileName: item?.fileName,
                            status: 1,
                            id: item?.id
                        }));
                        let obj2 = {
                            "registrationId": getLocalStorage("Application_registrationId"),
                            "kyc": companyloadItems
                        }

                        const response2 = await post({
                            url: getCompanyKYCPostApiUrl(getLocalStorage("Application_registrationId")),
                            obj: obj2
                        });
                        if (response2?.result) {
                            hideLoadingIndicator();
                        }
                        else if (response2?.error?.message) {
                            hideLoadingIndicator();
                            showToast(response2?.error?.message, "error")
                        }
                    } catch (error) {
                        hideLoadingIndicator();
                        console.error('Error occurred while submitting KYC details:', error);
                    }
                }
                hideLoadingIndicator();
                handleKyc(response?.result?.screenName)
            }
            else if (response?.error?.message) {
                hideLoadingIndicator();
                showToast(response?.error?.message, "error")
            }
        } catch (error) {
            hideLoadingIndicator();
            console.error('Error occurred while submitting KYC details:', error);
        }
    }

    const handleChange = async (fieldName: string, e: any, type: string, directorIndex?: number) => {
        showLoadingIndicator()
        const file = e.target.files?.[0];
        const fileTypeId = e.target.id;
        const documentId = e.target.getAttribute('data-document-id');
        const fileSize = file.size;
        const fileType = file.type;

        // if (fileSize > 1 * 1024 * 1024) {
        //     showToast("File size too large. Maximum size is 1 MB.", "error");
        //     return;
        // }

        if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            showToast("Invalid file type. .docx files are not allowed.", "error");
            return;
        }

        if (file) {
            const response: any = await fileUpload(file);

            if (response?.result?.[0]?.file_id) {
                hideLoadingIndicator()
                if (directorIndex !== undefined) {
                    setUploadedDocuments((prev: any) => {
                        const updated = [...(prev || [])];
                        if (!updated[directorIndex]) {
                            updated[directorIndex] = {};
                        }
                        updated[directorIndex][fieldName] = {
                            fileSize: response.result[0].file_size,
                            fileType: response.result[0].file_type,
                            fileId: response.result[0].file_id,
                            fileName: response.result[0].file_name,
                            fileTypeId,
                            id: documentId || null,
                        };
                        return updated;
                    });
                } else {
                    setCompanyUploadedDoc((prevCompanyDocs: any) => {
                        const updatedCompanyDocs = { ...(prevCompanyDocs || {}) };

                        updatedCompanyDocs[fieldName] = {
                            fileSize: response.result[0].file_size,
                            fileType: response.result[0].file_type,
                            fileId: response.result[0].file_id,
                            fileName: response.result[0].file_name,
                            fileTypeId: fileTypeId,
                            id: documentId || null,
                        };

                        return updatedCompanyDocs;
                    });
                }
            } else if (response?.error) {
                hideLoadingIndicator()
                const error = fieldName === '' ? null : response?.error?.message;
                setErrors((prevErrors: any) => ({
                    ...prevErrors,
                    [fieldName]: error
                }));
                showToast(response?.error?.message,'error');

            } else {
                hideLoadingIndicator()
                showToast("Something went wrong", "error");
            }
        }
    };
    const fileUpload = async (file: any) => {
        const data = new FormData();
        data.append("file", file);
        const response: any = await uploadFileApiUrl(data);
        return response;
    };
    const handleClearkyc = (fieldName: any) => {
        setDocuments({
            ...documents,
            [fieldName]: ""
        });
        setErrors({
            ...errors,
            [fieldName]: null
        });
    };
    const handleClear = (fieldName: string, directorIndex: number) => {
        setUploadedDocuments((prev: any) => {
            if (!Array.isArray(prev)) return prev;

            const updatedDocuments = [...prev];

            if (updatedDocuments[directorIndex]) {
                updatedDocuments[directorIndex][fieldName] = {
                    fileId: "",
                    fileName: ""
                };
            }

            return updatedDocuments;
        });

        setDocuments((prevDocuments: any) => ({
            ...prevDocuments,
            [fieldName]: {
                fileId: "",
                fileName: ""
            }
        }));

        setErrors((prevErrors: any) => ({
            ...prevErrors,
            [fieldName]: null
        }));
    };
    // useEffect(() => {
    //     const mandatoryFeild = Object.values(documents).every(value => value);
    //     if (mandatoryFeild) {
    //         setIsNextButtonDisabled(false);
    //     } else {
    //         setIsNextButtonDisabled(true);
    //     }
    // }, [isNextButtonDisabled, documents]);

    useEffect(() => {
        if (state) {
            const { registerType, enityType, PartnershipType } = state;
            if (registerType === RegisterTypeOptions[0].label && enityType === EntityTypeOptions[0].label) {
                setStepper(STEPPER_INDIVIDUAL)
            } else if (registerType === RegisterTypeOptions[0].label && (PartnershipType === PartnerShipTypeOptions[1].label || PartnershipType === PartnerShipTypeOptions[5].label)) {
                setStepper(STEPPER_INDIVIDUAL_DIRECTOR)
            }
        }
    }, [stepper])

    const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;

    const MainGridContainerStyle = css`
width: calc(100% + 30px);
display:contents;
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
    const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;
    return (
        <div>
            {visible ?
                <>{loadingComponent()}</> :
                <>        <div css={kycSubHeading}>
                    {KYC_VERIFICATION_HEADING}
                </div>
                    <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                        <Grid container spacing={2} css={MainGridContainerStyle}>
                            {directors?.length && directors.map((director: any, directorIndex: any) => (
                                <div key={directorIndex}>
                                    <h3>{`${director.firstName} ${director.lastName}`} Documents</h3>
                                    <Grid container spacing={2} >
                                        {docList?.map((item: any, index: any) => (
                                            <Grid item xs={12} sm={6} md={3} key={index}>
                                                <InputFile
                                                    key={index}
                                                    inputLabel={item.inputLabel}
                                                    onChange={(e) => handleChange(item.name, e, "partner", directorIndex)}
                                                    css={formControlStyle}
                                                    onClear={() => handleClear(item.name, directorIndex)}
                                                    registration={false}
                                                    docId={item.id}
                                                    value={uploadedDocuments[directorIndex]?.[item.name]?.fileName || uploadedDocuments[directorIndex]?.[item.name]?.fileName || item.value}
                                                    documentId={item.documentId}
                                                    fileName={uploadedDocuments[directorIndex]?.[item.name]?.fileName || uploadedDocuments[directorIndex]?.[item.name]?.fileName || item.value}
                                                    mandatory={item.mandatory}

                                               />
                                                {uploadedDocuments[directorIndex]?.[item.name]?.fileId && (
                                                    <PreviewComponent file={uploadedDocuments[directorIndex]?.[item.name]?.fileId} />
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container spacing={2} >
                                        {docList2?.map((item: any, index: any) => (
                                            <Grid item xs={12} sm={6} md={3} key={index}>
                                                <InputFile
                                                    inputLabel={item.inputLabel}
                                                    onChange={(e) => handleChange(item.name, e, "partner", directorIndex)}
                                                    css={formControlStyle}
                                                    onClear={() => handleClear(item.name, directorIndex)}
                                                    registration={false}
                                                    docId={item.id}
                                                    value={item.value}
                                                    documentId={item.documentId}
                                                    fileName={uploadedDocuments[directorIndex]?.[item.name]?.fileName || item.value}
                                                />
                                                {uploadedDocuments[directorIndex]?.[item.name]?.fileId && (
                                                    <PreviewComponent file={uploadedDocuments[directorIndex]?.[item.name]?.fileId} />
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>

                                </div>
                            ))}
                        </Grid>
                    </Box>
                    {(getLocalStorage('Application_EntityType') === "Business Entity") && (
                        <>
                            <div css={kycSubHeading}>
                                {KYC_VERIFICATION_COMPANY_HEADING}
                            </div>
                            <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                                <Grid container spacing={2} css={gridContainerStyle}>
                                    {companydocListState.map((item: any, index: any) => (
                                        <Grid item xs={12} sm={6} md={3} key={index}>
                                            <InputFile
                                                inputLabel={item.inputLabel}
                                                onChange={(e) => handleChange(item.name, e, "company")}
                                                css={formControlStyle}
                                                onClear={() => handleClearkyc(item.name)}
                                                registration={false}
                                                docId={item.id}
                                                value={item.value}
                                                documentId={item.documentId}
                                                fileName={companyUploadedDoc[item.name]?.fileName || item.value}
                                                mandatory={item.mandatory}

                                            />
                                            {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                                            {(companyUploadedDoc[item.name]?.fileId || item?.fileId) && <PreviewComponent file={companyUploadedDoc[item.name]?.fileId || item?.fileId} />}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </>
                    )}<div css={registrationButtonContainer}>
                        <CustomButton
                            label={BACK_LABEL}
                            onClick={() => setValue((pre: any) => pre - 1)}
                            variant="outlined"
                            css={regBackButton}
                        />

                        <CustomButton
                            label="Next"
                            onClick={() => handleNext()}
                            css={(filterByStatus?.toUpperCase() !== StringConstants.APPROVED_STATE && !isNextButtonDisabled ) ? regNextButton : regNextButtonDisabled}

                        />
                    </div>
                    <ToastContainer />
                </>}
        </div>
    )
}


export default KycDocumentes;
