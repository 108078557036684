/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { colors,  typographyInter } from '../../scss/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { icon } from '../../assets/Images';
import { useEffect, useState } from 'react';
import MobileSideBar from '../MobileSideBar/MobileSideBar';
import { useLocation } from 'react-router-dom';
import { StringConstants } from '../../constants/Strings';
import { deleteLocalStorage, getLocalStorage, setLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import { get } from '../../apiManger/apiMethods';
import { getProfileGETApiUrl } from '../../apiManger/apiConstants';

const mainSection = css`

 @media (max-width: 768px) {
   height: 80px;
  background-color: ${colors.white};
  }
`
const dashboardHeaderContainer = css`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(100, 130, 130, 0.25);

  @media (max-width: 768px) {
    display:none;
  }
`;
const HeaderSearchSection = css`
margin-left:45px;
display:flex;
align-items: center;
 color: ${colors.lightBlack};
  ${typographyInter('500', '20px')};
  
`
const ProfileSection = css`
margin-right:25px;
display:flex;
gap:10px;
color: ${colors.lightBlack};
${typographyInter('500', '14px')};
cursor:pointer;
align-items: center;
`
const MobileHeader = css`
 display: none;
 
@media (max-width: 768px) {
   width:100%;
   color: ${colors.lightBlack};
   ${typographyInter('500', '16px')};
       z-index: 1;
 height:40px;
 display:flex;
justify-content:space-between;
 align-items:center; 
   position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color:${colors.white};
    padding:20px 0px;
}
`

const cursorCSS = css`
cursor:pointer;
`
const leftSection = css`
padding-left:15px;
`
const RightSection = css`
@media (max-width: 768px) {
width:60%;
text-align:left;
}
`

const DashboardHeader = () => {
  const navigate = useNavigate();
  const role =  getLocalStorage("role");
  const [userName,setUserName] = useState(getLocalStorage("userName"))
  const [showSideBar, setShowSideBar] = useState(false);
  const [activePage, setActivePage] = useState<any>();
  const {DASHBOARD,VERIFY_KYC_HEADING,VERIFY_CPV_HEADING,PAYOUT_STRUCTURE,SANCTION,ACCOUNT,
    ESIGN,LOAN_APLLICATION,PAYOUT_DETAILS,SANCTION_label,PAYOUT_DETAILS_ROUTE,LOAN_ROUTE,
    ESIGN_ROUTE,ACCOUNT_ROUTE,SANACTION_TWO_ROUTE,SANCTION_ROUTE,PAYOUT_ROUTE,INITIATE_CPV_ROUTE,
    INITIATE_ROUTE,DASHBOARD_ROUTE
  } = StringConstants;
  const location = useLocation();
 
  useEffect(() => {
      deleteLocalStorage("final")

      // // need to remove below once login token integrated
      // deleteLocalStorage("access_token")
      // deleteLocalStorage("refresh_token")

    const path = location.pathname;
    const lastSegment = path.substring(path.lastIndexOf('/') + 1);
    let title :any = ""
    if(lastSegment.includes(DASHBOARD_ROUTE)){
        title = DASHBOARD
    }else if(lastSegment.includes(INITIATE_ROUTE)){
 title = VERIFY_KYC_HEADING
    }else if(lastSegment.includes(INITIATE_CPV_ROUTE)){
       title = VERIFY_CPV_HEADING
    }else if(lastSegment === PAYOUT_ROUTE){
       title = PAYOUT_STRUCTURE
    }else if(lastSegment === SANCTION_ROUTE){
       title = SANCTION
    }else if(lastSegment === SANACTION_TWO_ROUTE){
      title= SANCTION_label
    }else if(lastSegment.includes(ACCOUNT_ROUTE)){
      title=ACCOUNT
    }else if(lastSegment.includes(ESIGN_ROUTE)){
      title=ESIGN
    }else if(lastSegment.includes(LOAN_ROUTE)){
      title=LOAN_APLLICATION
    }else if(lastSegment.includes(PAYOUT_DETAILS_ROUTE)){
      title=PAYOUT_DETAILS
    }
    setActivePage(title);
  }, [location]);
  useEffect(() => {
    if(userName === undefined || window?.location?.pathname?.includes('/account')){
    fetchProfileData();
    }
},[userName]);
const fetchProfileData = async () => {
    try {
       
            const response = await get({
              url: getProfileGETApiUrl()
            });
            if (response?.result) {
              setLocalStorage("userName",`${response.result.firstName} ${response.result.lastName}`)
              setUserName(`${response.result.firstName} ${response.result.lastName}`) 
            }
          } catch (error) {
            console.error("Error fetching product vs applications data:", error);
          } 
}
  return (
    <div css={mainSection}>
      <div css={dashboardHeaderContainer}>
        <div css={HeaderSearchSection}>
        {/* { `Hi, ${userName}`} */}
        </div>
        <div css={ProfileSection} onClick={() => navigate('/account')}>
          <div>{userName}</div>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <div css={MobileHeader}>
        <div css={leftSection}>
          <img css={cursorCSS} src={icon.HamburgerIcon} alt="no_hambarger" onClick={() => setShowSideBar(!showSideBar)} />
        </div>
        <div css={RightSection}>{activePage}</div>
      </div>
      {showSideBar && (
        <MobileSideBar
          onClick={() => setShowSideBar(false)}
          showSideBar={showSideBar}
        />
      )}
    </div>

  );
};

export default DashboardHeader;
