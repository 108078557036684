/** @jsxImportSource @emotion/react */
import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { css } from '@emotion/react';
import { colors, typographyInter } from '../../scss/colors';

interface CardProps {
  title: string;
  onMoreVertClick?: () => void;
  children?: React.ReactNode;
  width?:any;
}

const CustomCard: React.FC<CardProps> = ({
  title,
  children,
  width = '200px'
}) => {
 
const  cardCotainer = css`
width: ${width};
border:1px solid ${colors.stroke};
border-radius:10px;
box-shadow:none;
@media (max-width: 768px) {
width: 100%;
margin-top: 10px;
}
`
const cardHeader = css`
  padding: 10px 10px;
  justify-content: space-between;
   text-align:left;
    ${typographyInter('500', '12px')};
    color: ${colors.tableLabel};
`

  return (
    <Card css={cardCotainer}>
      <CardHeader
        css={cardHeader}
      subheader={title}     
      subheaderTypographyProps={{
        sx: { color: '#464B4F', fontFamily: 'Inter', fontWeight: '500', fontSize: '12px' },
      }}  />
        <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CustomCard;
