import { getDashboardSummaryGETApiUrl } from "../../apiManger/apiConstants";
import { get, post } from "../../apiManger/apiMethods";
import { data } from "./data";
let URL = {
    renderConfig: () => ``,
}

export const getRenderData = async (applicationId?: string) => {
    try {
        const response = await post({
            url: getDashboardSummaryGETApiUrl(),
            obj: {
                "dashboard_id" : "COL_ID_002"
            }
        });
    //    const response={
    //     'dashboard_data':[]
    //    };
       return Promise.resolve(response.dashboard_data);
    } catch (error) {
        console.error("Error fetching dashboard summary:", error);
        return Promise.resolve([]); 
      }

    
}

